<ion-header class="translucent" collapse="fade">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button
        defaultHref="/"
        class="icon-back-button"
        text=""
      ></ion-back-button>
    </ion-buttons>
    <ion-title>Investment guide</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content fullscreen class="translucent">
  @if (beforeInvestment) {
    <div id="first-investment" class="ion-text-center">
      <img
        ngSrc="assets/core/graphics/construction.svg"
        alt="Construction graphic"
        width="121"
        height="100"
        [loading]="storage.assetLoadingMode"
      />
      <ion-header collapse="condense">
        <ion-toolbar color="none">
          <ion-title size="large">Your first investment</ion-title>
        </ion-toolbar>
      </ion-header>
      <p class="ion-padding-horizontal ion-padding-bottom">
        Looks like this is your first investment with Buildshare! Let's go over
        how it works so you're ready to get started.
      </p>
    </div>
  }

  <ion-grid class="ion-no-padding">
    <ion-row id="how-it-works" class="ion-margin-top">
      <ion-col>
        <h3 class="ion-margin-top">How it works</h3>
        <swiper-container
          slides-per-view="auto"
          space-between="0"
          class="responsive-swipe-cards"
        >
          <swiper-slide>
            <ion-card>
              <div class="img-container">
                <img
                  ngSrc="assets/account/choose_amount.jpg"
                  alt="Choose amount"
                  fill
                  priority
                />
              </div>
              <ion-card-header>
                <ion-card-subtitle>Step 1</ion-card-subtitle>
                <ion-card-title>Choose an amount</ion-card-title>
              </ion-card-header>
              <ion-card-content>
                <p>
                  Select how much you'd like to invest and view your projected
                  interest to help you decide.
                </p>
              </ion-card-content>
            </ion-card>
          </swiper-slide>

          <swiper-slide>
            <ion-card>
              <div class="img-container">
                <img
                  ngSrc="assets/core/graphics/payouts.jpg"
                  alt="Payouts"
                  fill
                  priority
                />
              </div>
              <ion-card-header>
                <ion-card-subtitle>Step 2</ion-card-subtitle>
                <ion-card-title>Set up payouts</ion-card-title>
              </ion-card-header>
              <ion-card-content>
                <p>
                  Set up your bank account details with our payments partner,
                  Stripe, which we'll use to pay you your profits.
                </p>
              </ion-card-content>
            </ion-card>
          </swiper-slide>

          <swiper-slide>
            <ion-card>
              <div class="img-container">
                <img
                  ngSrc="assets/core/graphics/pay.jpg"
                  alt="Payment graphic"
                  fill
                  [loading]="storage.assetLoadingMode"
                />
              </div>
              <ion-card-header>
                <ion-card-subtitle>Step 3</ion-card-subtitle>
                <ion-card-title>Confirm and pay</ion-card-title>
              </ion-card-header>
              <ion-card-content>
                <p>
                  Confirm all your investment details, and once you're ready,
                  make your payment securely through Stripe.
                </p>
              </ion-card-content>
            </ion-card>
          </swiper-slide>

          <swiper-slide>
            <ion-card>
              <div class="img-container">
                <img
                  ngSrc="assets/account/profits.jpg"
                  alt="Money graphic"
                  fill
                  [loading]="storage.assetLoadingMode"
                />
              </div>
              <ion-card-header>
                <ion-card-subtitle>Step 4</ion-card-subtitle>
                <ion-card-title>Earn your interest</ion-card-title>
              </ion-card-header>
              <ion-card-content>
                <p>
                  After the project starts you'll see your interest build along
                  with regular construction updates in your project feed.
                </p>
              </ion-card-content>
            </ion-card>
          </swiper-slide>

          <swiper-slide>
            <ion-card>
              <div class="img-container">
                <img
                  ngSrc="assets/account/developers.jpg"
                  alt="Builders graphic"
                  fill
                  [loading]="storage.assetLoadingMode"
                />
              </div>
              <ion-card-header>
                <ion-card-subtitle>Step 5</ion-card-subtitle>
                <ion-card-title>Complete the project</ion-card-title>
              </ion-card-header>
              <ion-card-content>
                <p>
                  On completion your original investment plus all the interest
                  earned is paid out to your bank account, ready for your next
                  project!
                </p>
              </ion-card-content>
            </ion-card>
          </swiper-slide>
        </swiper-container>
      </ion-col>
    </ion-row>

    <ion-row id="how-it-works" class="ion-margin-top">
      <ion-col size="12">
        <h3 class="ion-margin-top">The risks</h3>
      </ion-col>
      <ion-col size="12" class="ion-padding-horizontal">
        <p class="ion-padding-horizontal">
          As with any investment there are risks. Please take a moment to
          understand them.
        </p>
      </ion-col>

      <ion-col size="12">
        <ion-card>
          <ion-accordion-group>
            <ion-accordion
              value="delays"
              toggleIcon="caret-down-circle-outline"
              toggleIconSlot="start"
            >
              <ion-item lines="full" slot="header">
                <ion-label
                  class="ion-text-wrap ion-padding-top ion-padding-bottom"
                  >Projects can encounter delays</ion-label
                >
              </ion-item>
              <ion-item slot="content">
                <ion-label
                  class="ion-text-wrap ion-padding ion-text-justify"
                  [innerHtml]="riskDescriptions.delays"
                >
                </ion-label>
              </ion-item>
            </ion-accordion>
            <ion-accordion
              value="defaults"
              toggleIcon="caret-down-circle-outline"
              toggleIconSlot="start"
            >
              <ion-item lines="full" slot="header">
                <ion-label
                  class="ion-text-wrap ion-padding-top ion-padding-bottom"
                  >Developers may default</ion-label
                >
              </ion-item>
              <ion-item slot="content">
                <ion-label
                  class="ion-text-wrap ion-padding ion-text-justify"
                  [innerHtml]="riskDescriptions.defaults"
                >
                </ion-label>
              </ion-item>
            </ion-accordion>
            <ion-accordion
              value="valuation"
              toggleIcon="caret-down-circle-outline"
              toggleIconSlot="start"
            >
              <ion-item lines="full" slot="header">
                <ion-label
                  class="ion-text-wrap ion-padding-top ion-padding-bottom"
                  >Investments may be illiquid
                </ion-label>
              </ion-item>
              <ion-item slot="content">
                <ion-label
                  class="ion-text-wrap ion-padding ion-text-justify"
                  [innerHtml]="riskDescriptions.illiquidity"
                >
                </ion-label>
              </ion-item>
            </ion-accordion>
          </ion-accordion-group>
        </ion-card>
        <ion-item lines="none" color="none">
          <ion-icon
            name="shield-checkmark"
            color="success"
            slot="start"
          ></ion-icon>
          <ion-label
            id="restricted-investor"
            class="ion-text-wrap ion-padding-vertical"
            [innerHtml]="riskDescriptions.restrictedInvestor"
          >
          </ion-label>
        </ion-item>
      </ion-col>

      <ion-col class="ion-text-center" size="12">
        <ion-button fill="clear" routerLink="/risk">
          View full risk statement
          <ion-icon slot="end" name="chevron-forward"></ion-icon>
        </ion-button>
      </ion-col>
    </ion-row>

    <ion-row class="ion-margin-vertical">
      <ion-col size="12">
        <h3>Common questions</h3>
      </ion-col>

      <ion-col size="12">
        <ion-card>
          @if (faqs$ | async; as faqs) {
            <ion-accordion-group
              (ionChange)="accordionGroupChange($event, faqs)"
            >
              @for (faq of faqs; track faq) {
                <ion-accordion
                  [value]="faq.id"
                  toggleIcon="caret-down-circle-outline"
                  toggleIconSlot="start"
                >
                  <ion-item lines="full" slot="header">
                    <ion-label
                      class="ion-text-wrap ion-padding-top ion-padding-bottom"
                      >{{ faq.q }}</ion-label
                    >
                  </ion-item>
                  <ion-item slot="content">
                    <ion-label
                      class="ion-text-wrap ion-padding ion-text-justify"
                      [innerHtml]="faq.a"
                    ></ion-label>
                  </ion-item>
                </ion-accordion>
              }
            </ion-accordion-group>
          } @else {
            @for (i of [0, 1, 2]; track i) {
              <ion-item>
                <ion-label>
                  <ion-skeleton-text
                    animated
                    style="width: 60%; height: 15px"
                    class="ion-margin"
                  ></ion-skeleton-text>
                </ion-label>
              </ion-item>
              <ion-item>
                <ion-label>
                  <div class="ion-margin">
                    <ion-skeleton-text
                      animated
                      style="width: 100%; height: 15px"
                    ></ion-skeleton-text>
                    <ion-skeleton-text
                      animated
                      style="width: 40%; height: 15px"
                    ></ion-skeleton-text>
                  </div>
                </ion-label>
              </ion-item>
              <ion-item>
                <ion-label>
                  <ion-skeleton-text
                    animated
                    style="width: 80%; height: 15px"
                    class="ion-margin"
                  ></ion-skeleton-text>
                </ion-label>
              </ion-item>
            }
          }
        </ion-card>
      </ion-col>
      <ion-col class="ion-text-center" size="12">
        <ion-button fill="clear" routerLink="/faqs">
          View the full FAQs
          <ion-icon slot="end" name="chevron-forward"></ion-icon>
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>

@if (beforeInvestment) {
  <ion-footer translucent class="ion-padding translucent" collapse="fade">
    <ion-button expand="block" [routerLink]="['../select-amount']"
      >I'm ready</ion-button
    >
  </ion-footer>
}
